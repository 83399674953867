<template>
  <div class="mobileHome">
    <div class="banner">
      <div class="bannerTit">智慧酒店·五星体验打造专家</div>
      <div class="bannerText">BOT智能科技多年的技术积累，</div>
      <div class="bannerText bannerTexts">倾尽全力打造出高稳定性的无线化智能酒店解决方案，</div>
      <div class="bannerText bannerTexts">并始终保持高可定制化能力。</div>
    </div>
    <!-- 超级主机 -->
    <!--<div class="gateway">
       <div class="gatewayTit">超级主机</div>
      <div class="gatewayTits">一个主机，改变一切</div>
      <div class="gatewayText">强大的系统硬件，集不同设备和功能于一身。</div>
      <div class="gatewayText gatewayTexts">更有优秀稳定的配套软硬件解决方案，</div>
      <div class="gatewayText gatewayTexts">让您拥有舒适，自然的入住体验。</div>
      <router-link to="/mobile/ProGateway" class="more">
        <div class="moreTxt">了解更多</div>
      </router-link>
      <img class="gatewayImg" src="../../assets/mobile/index/gateway1.png" />
      <div class="ul">
        <div class="li">无线设备控制</div>
        <div class="li">云端互联不掉线</div>
        <div class="li">自由接入完全定制</div>
      </div>
    </div> -->
    <!-- 资质认证 -->
    <div class="certification">
      <div class="certificationTit">资质认证</div>
      <div class="line"></div>
      <div class="certiBox">
          <div class="certiTxt">累计已获<span class="spanText">60+</span>项发明专利、实用新型专利</div>
          <div class="certiTxt mt">软件著作权证书及<span class="spanText2">高新企业、中国信通院</span>等认证</div>
          <div class="gridBox">
            <div class="item">
              <div class="itemImg">
                <img class="itemImg1"  src="../../assets/mobile/index/item1.png" />
              </div>
              <div class="itemTit">安防工程资质</div>
            </div>
            <div class="item">
              <div class="itemImg">
                <img class="itemImg1" src="../../assets/mobile/index/item2.png" />
              </div>
              <div class="itemTit">高新技术企业</div>
            </div>
            <div class="item">
              <div class="itemImg">
                <img class="itemImg1" src="../../assets/mobile/index/item3.png" />
              </div>
              <div class="itemTit">网络二级等保</div>
            </div>
            <div class="item">
              <div class="itemImg">
                <img class="itemImg2" src="../../assets/mobile/index/item12.png" />
              </div>
              <div class="itemTit">中国信通院认证</div>
            </div>
            <div class="item">
              <div class="itemImg">
                <img class="itemImg2" src="../../assets/mobile/index/item6.png" />
              </div>
              <div class="itemTit">发明专利证书</div>
            </div>
            <div class="item">
              <div class="itemImg">
                <img class="itemImg2" src="../../assets/mobile/index/item10.png" />
              </div>
              <div class="itemTit">CQC试验报告</div>
            </div>
            <div class="item">
              <div class="itemImg">
                <img class="itemImg2" src="../../assets/mobile/index/item7.png" />
              </div>
              <div class="itemTit">实用新型专利</div>
            </div>
            <div class="item">
              <div class="itemImg">
                <img class="itemImg2" src="../../assets/mobile/index/item8.png" />
              </div>
              <div class="itemTit">实用新型专利</div>
            </div>
            <div class="item">
              <div class="itemImg">
                <img class="itemImg2" src="../../assets/mobile/index/item9.png" />
              </div>
              <div class="itemTit">实用新型专利</div>
            </div>
            <div class="item">
              <div class="itemImg">
                <img class="itemImg2" src="../../assets/mobile/index/item11.png" />
              </div>
              <div class="itemTit">管理体系认证</div>
            </div>
           
            <div class="item">
              <div class="itemImg">
                <img class="itemImg2" src="../../assets/mobile/index/item4.png" />
              </div>
              <div class="itemTit">软著登记证书</div>
            </div>
            <div class="item">
              <div class="itemImg">
                <img class="itemImg2" src="../../assets/mobile/index/item5.png" />
              </div>
              <div class="itemTit">软著登记证书</div>
            </div>
        </div>
      </div>
     
    </div>
    <!-- 全屋智能 -->
     <div class="intel">
      <div class="intelTit">全屋智能</div>
      <div class="line"></div>
      <MobileWhole></MobileWhole>
     </div>
    <!-- bot -->
    <div class="bot">
      <div class="botTit">BOT客控系统</div>
      <div class="line"></div>
      <div class="botBox">
        <div class="botTxt">全新的 UI 美学，简单而符合视觉的界面，</div>
        <div class="botTxt botTxts">在提升效率的同时又不失掌控。</div>
        <div class="botTxt botTxts">为用户打造完美的人性化智能设备体验。</div>
        <img src="../../assets/mobile/index/bot.png" class="botImg" />
        <!-- <router-link to="/mobile/ProGateway" class="gate">
          <div class="gateTit">超级主机</div>
          <div class="gateTxt">一个主机，改变一切</div>
        </router-link> -->
      </div>
    </div>
    <!-- 跳转 -->
    <!-- <router-link to="/mobile/ProCurtain" class="pro">
      <div class="proBox">
        <div class="proTxt">
          <div class="txts">窗帘电机</div>
          <div class="im"></div>
        </div>
      </div>
    </router-link> 
    <div class="pros">
      <router-link to="/mobile/ProUsb" class="pro1">
        <div class="proBox">
          <div class="proTxt">
            <div class="txts">五孔带USB电源面板</div>
            <div class="im"></div>
          </div>
        </div>
      </router-link>
      <router-link to="/mobile/ProSwitch" class="pro1">
        <div class="proBox">
          <div class="proTxt">
            <div class="txts">智能开关</div>
            <div class="im"></div>
          </div>
        </div>
      </router-link>
    </div> -->
    <!-- 定制 -->
    <router-link to="/mobile/custom" class="sec">
      <div class="secBox">
        <div class="tit">智能定制</div>
        <div class="tits">快速获得只属于你的专属方案</div>
        <div class="txt">
          <div class="txts">了解更多</div>
          <div class="im"></div>
        </div>
      </div>
    </router-link>
    <!-- 案例 -->
    <router-link to="/mobile/demo" class="sec">
      <div class="secBox">
        <div class="tit">精选案例</div>
        <div class="tits">查看我们为客户打造的专属解决方案</div>
        <div class="txt">
          <div class="txts">了解更多</div>
          <div class="im"></div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import MobileWhole from './MobileWhole.vue';
export default {
  components:{
    MobileWhole
  },
  created() {},
  methods: {}
};
</script>
<style scoped lang="less">
.mobileHome {
  background-color: #fff;
}
.banner {
  width: 100%;
  height: 8rem;
  font-size: 0.48rem;
  line-height: 0.48rem;
  font-weight: bold;
  color: #333333;
  background-image: url(../../assets/mobile/index/banner.png);
  background-size: 100% 100%;
  .bannerTit {
    padding-top: 2.35rem;
    font-weight: bold;
  }
  .bannerText {
    font-size: 0.24rem;
    line-height: 0.24rem;
    margin-top: 0.24rem;
    font-weight: 400;
  }
  .bannerTexts {
    margin-top: 0.16rem;
  }
}
.gateway {
  padding-top: 0.79rem;
  font-size: 0.36rem;
  line-height: 0.36rem;
  font-weight: bold;
  color: #333333;
  .gatewayTits {
    font-size: 0.24rem;
    margin-top: 0.16rem;
  }
  .gatewayText {
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
    color: #333;
    margin-top: 0.3rem;
  }
  .gatewayTexts {
    margin-top: 0.16rem;
  }
  .more {
    width: 2.4rem;
    height: 0.8rem;
    margin: 0 auto;
    border: 1px solid #ff9000;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.39rem;
    .moreTxt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #ff9000;
    }
  }
  .gatewayImg {
    display: block;
    margin: 0 auto;
    margin-top: 0.4rem;
    width: 2.72rem;
    height: 5.11rem;
  }

  .ul {
    margin-top: 0.6rem;
    display: flex;
    justify-content: center;
    .li {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      padding: 0 0.21rem;
      border-right: 2px solid #ff9000;
      &:last-of-type {
        border-right: 0;
      }
    }
  }
}

.certification{
  width: 100%;
  padding-top: 0.79rem;
  .certificationTit{
    width: 100%;
    height: 0.6rem;
    font-size: 0.36rem;
    position: relative;
    color: #333;
    font-weight: bold;
  }
  .line {
    position: absolute;
    left: calc((100% - 0.4rem) / 2);
    width: 0.4rem;
    height: 2px;
    background: #ff9000;
  }
  .certiBox{
    padding: 0 0.1rem;
    padding-top: 0.4rem;
    .certiTxt{
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 300;
      color: #2D2D2D;

      .spanText{
        font-size: 0.36rem;
        color: #ff9000;
      }
      .spanText2{
        color: #1A428B;
      }
    }
    .mt{
      margin-top: 0.16rem;
      margin-bottom: 0.32rem;
    }
  
  .gridBox{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item{
        margin-top: 0.16rem;
        .itemImg{
          width: 2.3rem;
          height: 2.19rem;
          border: 0.01rem solid #B4B4B4;
          display: flex;
          align-items: center;
          justify-content: center;
          .itemImg1{
            width: 2.1rem;
            height: 1.49rem;
          }
          .itemImg2{
            width: 1.42rem;
            height: 2.03rem;
          }
        }
        .itemTit{
          font-size: 0.24rem;
          font-weight: 400;
          color: #000000;
          margin-top: 0.1rem;
        }
    }
  }
  }
}

.intel{
  width: 100%;
  margin-top: 0.49rem;
  .intelTit{
    width: 100%;
    height: 0.6rem;
    font-size: 0.36rem;
    position: relative;
    color: #333;
    font-weight: bold;
  }
  .line {
    position: absolute;
    left: calc((100% - 0.4rem) / 2);
    width: 0.4rem;
    height: 2px;
    background: #ff9000;
  }
}

.bot {
  width: 100%;
  margin-top: 0.78rem;
  .botTit {
    width: 100%;
    height: 0.6rem;
    font-size: 0.36rem;
    // box-shadow: -5px 5px 10px -6px rgba(0, 0, 0, 0.1);
    position: relative;
    color: #333;
    font-weight: bold;
  }
  .line {
    position: absolute;
    left: calc((100% - 0.4rem) / 2);
    width: 0.4rem;
    height: 2px;
    background: #ff9000;
  }
  .botBox {
    padding: 0 0.3rem;
    padding-top: 0.4rem;
    .botTxt {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 300;
      color: #333333;
    }
    .botTxts {
      margin-top: 0.16rem;
    }
    .botImg {
      display: block;
      margin-top: 0.3rem;
      width: 100%;
      height: 6rem;
    }
    .gate {
      display: block;
      margin-top: 0.8rem;
      width: 100%;
      height: 6rem;
      background-image: url(../../assets/mobile/index/gateway2.png);
      background-size: 100% 100%;
      .gateTit {
        padding-top: 0.79rem;
        font-size: 0.3rem;
        line-height: 0.3rem;
        font-weight: bold;
        color: #333333;
      }
      .gateTxt {
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
        color: #333333;
        margin-top: 0.2rem;
      }
    }
  }
}
.pro {
  display: block;
  padding: 0 0.3rem;
  margin-top: 0.3rem;
  background-color: #fff;
  .proBox {
    width: 100%;
    height: 3rem;
    background-image: url(../../assets/mobile/index/pro1.png);
    background-size: 100% 100%;
    .proTxt {
      padding-top: 2.64rem;
      margin-left: 0.12rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      display: flex;
      align-items: center;
      .im {
        width: 0.16rem;
        height: 0.2rem;
        background-image: url("../../assets/mobile/index/arrow.png");
        background-size: 100% 100%;
        margin-left: 0.12rem;
        margin-top: -1px;
      }
    }
  }
}
.pros {
  display: flex;
  padding: 0px 0.3rem;
  margin-top: 0.3rem;
  .pro1 {
    width: 50%;
    height: 3rem;
    margin-right: 0.3rem;
    background-image: url(../../assets/mobile/index/pro2.png);
    background-size: 100% 100%;
    &:last-of-type {
      margin-right: 0px;
      background-image: url(../../assets/mobile/index/pro3.png);
      background-size: 100% 100%;
    }
    .proTxt {
      padding-top: 2.65rem;
      margin-left: 0.12rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      display: flex;
      align-items: center;
      .im {
        width: 0.16rem;
        height: 0.2rem;
        background-image: url("../../assets/mobile/index/arrow.png");
        background-size: 100% 100%;
        margin-left: 0.12rem;
        margin-top: -1px;
      }
    }
  }
}
.sec {
  display: block;
  padding: 0px 0.3rem;
  margin-top: 0.8rem;
  font-size: 0.36rem;
  line-height: 0.36rem;
  font-weight: bold;
  color: #ffffff;
  &:last-of-type {
    margin-top: 0.3rem;
    padding-bottom: 0.8rem;
    .secBox {
      background-image: url("../../assets/mobile/index/demo.png");
      background-size: 100% 100%;
    }
  }
  .secBox {
    width: 100%;
    height: 4rem;
    background-image: url("../../assets/mobile/index/custom.png");
    background-size: 100% 100%;
    text-align: center;
    .tit {
      padding-top: 1.27rem;
    }
    .tits {
      margin-top: 0.16rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
    }
    .txt {
      margin-top: 0.47rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      .im {
        width: 0.16rem;
        height: 0.2rem;
        background-image: url("../../assets/mobile/index/arrows.png");
        background-size: 100% 100%;
        margin-left: 0.12rem;
        margin-top: -1px;
      }
    }
  }
}
</style>
